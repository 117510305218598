<template>
  <main v-if="topic">
    <div class="topic-header">
      <div class="topic-title">
        <span v-if="topic.isTop > 0">【置顶】</span>

        <span v-if="topic.isBest > 0">【精华】</span>

        <span v-if="topic.payTotalCredit > 0"
          >【悬赏】{{ topic.payTotalCredit }}</span
        >

        <i style="font-style: normal" class="topic-title-txt">{{
          topic.title
        }}</i>
      </div>
      <div class="topic-author-info">
        <router-link :to="{ name: 'Personal', query: { uid: topic.uid } }">
          <img :src="topic.user.userImg" class="avatar" alt="" />
        </router-link>
        <div class="author-detail">
          <div class="name">
            <router-link :to="{ name: 'Personal', query: { uid: topic.uid } }">
              {{ topic.user.nickName }}
            </router-link>
          </div>
          <div class="date">
            <router-link :to="{ name: 'Personal', query: { uid: topic.uid } }">
              {{ dateFormat(topic.createTime) }}
            </router-link>

            <router-link
              style="marginLeft:10px"
              v-if="topic.professorid > 0 && my && my.isAoke == 1"
              :to="{name:'NeiOrderDetail',query:{id:topic.professorid}}"
              >查看订单</router-link
            >
          </div>

          <div v-if="myuserid != 0 && my && my.id == topic.uid">
            <span
              class="topic-btn"
              id="edit"
              style="cursor: pointer; float: right; right: 45px"
              @click="editTopic(topic.id)"
              >编辑</span
            >
            <span
              class="topic-btn"
              id="del"
              style="cursor: pointer; float: right"
              data-id="${(topic.id)?c}"
              @click="del(topic.id)"
              >删除</span
            >
            <span
              class="topic-btn"
              id="apply"
              style="cursor: pointer; float: right; right: 79.987px"
              @click="apply(topic.id)"
              >申精</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="topic-content">
      <div class="topic-content-txt" v-html="topic.content"></div>
      <div class="topic-picture my-gallery" data-pswp-uid="1">
        <figure
          v-for="(item, index) in topic.picLinks"
          :key="index"
          class="topic-img-list"
        >
          <div class="img-dv">
            <img :src="item" />
          </div>
        </figure>

        <video
          v-if="topic.videos && topic.videos != ''"
          controls
          :src="topic.videos"
        ></video>
      </div>
    </div>
    <!-- <recommend title="大家都在看" :list="recommendList"></recommend> -->
    <star
      :likeCount="likeCount"
      :likeUser="likeUser"
      :likeUserCount="likeUserCount"
      @star="onStar"
    ></star>
    <comment
      :listComment="listComment"
      :totalCount="totalCount"
      @star="onStarComment"
    ></comment>
    <topic-comment
      :topic="topic"
      v-model="comment"
      @star="onStar"
      @collect="onCollect"
      @submit="subComment"
      :isFav="isFav"
    ></topic-comment>
  </main>
  <loading :options="loadOption" v-else></loading>
  <preview
    v-if="imgList.length && previewing"
    @close="closePreview"
    :imgList="imgList"
    :currentIndex="currentIndex"
  ></preview>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  nextTick,
  inject,
} from "vue";

//  components
import Loading from "@/components/UI/Loading";
// import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
import { RecommnedList } from "@/components/common/Recommend.vue";
import Star from "@/components/common/Star.vue";
import Comment from "@/components/common/Comment.vue";
import TopicComment from "@/components/common/TopicComment.vue";
import Preview from "@/components/UI/Preview";
import { ImgItem } from "@/components/UI/Preview/src/Preview.vue";
import { useRoute, useRouter } from "vue-router";
import $ from "jquery";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import useFootPrint from "@/hooks/useFootPrint";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import wx from "weixin-sdk-js";
export default defineComponent({
  name: "TopicDetail",
  components: {
    // Recommend,
    Loading,
    Star,
    Comment,
    Preview,
    TopicComment,
  },

  setup() {
    const shareTitle = ref('')
    //  get page params
    const route = useRoute();
    const id = route.query.topicId;
    const topic = ref<any>(null);
    const my = ref(null);
    const myuserid = ref(0);
    //  recommend
    const recommendList = reactive<RecommnedList[]>([]);
    const likeCount = ref(0);
    const likeUserCount = ref(0);
    const likeUser = ref<object>([]);
    //  comment
    const listComment = ref<object>([]);
    const totalCount = ref(0);
    const loading = ref(true);
    const isFav = ref(false);
    const { setFootItem } = useFootPrint();
    //  async data
    function getRenderData(id: string) {
      //  async data
      topic.value = null;
      loading.value = true;
      recommendList.length = 0;
      axios
        .get("/M/Discover/Topics/" + id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            data.topic.picLinks = data.topic.picLinks ? data.topic.picLinks.split(",") : [];
            myuserid.value = data.myuserid;
            topic.value = data.topic;
            isFav.value = data.isFav;
            shareTitle.value = data.topic.title
            // document.title = data.topic.title + '-伟业计量'
            route.meta.title = data.topic.title;
            route.meta.content.keywords = data.topic.keyword;
            route.meta.content.description = data.topic.summary;
          }
        })
        .catch((err) => {
          console.log(err);

         console.log(err)
          loading.value = false;
        });
          axios
        .get("/M/Discover/TopicsRelation/" + id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            my.value = data.my;
            data.randList.forEach((item: any) => {
              const link = {
                name: "TopicDetail",
                query: { topicId: item.id },
              };
              recommendList.push({
                id: item.id,
                title: item.title,
                link,
              });
            });
            likeCount.value = topic.value.isAlreadyZan ? 1 : 0;
            // likeUserCount.value = data.likeUser ? data.likeUser.length : 0;
            // likeUser.value = data.likeUser;
            likeUserCount.value = topic.value.likesList ? topic.value.likesList.length : 0;
            likeUser.value = topic.value.likesList;
            totalCount.value = data.totalCount;
            listComment.value = data.postList;
            loading.value = false;


            /**
             *  存足迹
             */
            setFootItem({
              type: "topic",
              title: topic.value.title,
              summary: topic.value.summary,
              url: {
                name: "TopicDetail",
                query: { topicId: route.query.topicId },
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);

         console.log(err)
          loading.value = false;
        });
    }
    getRenderData(id as string);
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    //  preview picture
    const imgList = reactive<ImgItem[]>([]);
    const currentIndex = ref(0);
    const previewing = ref(false);
    function getSrcIndex(src: string, array: JQuery<HTMLElement>): number {
      let index = 0;
      array.each(function (i, item) {
        if ($(this).prop("src") == src) {
          index = i;
        }
      });
      return index;
    }

    onMounted(() => {
      $(document).on("click", ".topic-content img", function (e: Event) {
        const src = $(this).prop("src");
        currentIndex.value = getSrcIndex(src, $(".topic-content img"));
        previewing.value = true;
      });
    });
    function closePreview() {
      previewing.value = false;
    }

    watch(
      () => {
        return shareTitle.value
      },
      (to, from) => {
        const isweixin = ref(false);
        const ua = navigator.userAgent.toLowerCase();
        const reg = /MicroMessenger/i;
        isweixin.value = reg.test(ua);
        if (isweixin.value) {
          nextTick(() => {
            // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
            const link = window.location.href;
            const title = document.title;
            const desc = (document.querySelectorAll(
              "meta[name=description]"
            )[0] as any).content;
            // console.log(desc);

            const formData = new FormData();
            formData.append("url", link);
            axios.post("/M/Server/getweixin", formData).then((res: any) => {
              const data = res.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appid, // 必填，公众号的唯一标识
                timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.str, // 必填，签名
                jsApiList: [
                  "checkJsApi",
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ], // 必填，需要使用的JS接口列表
              });
              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({
                  title: to+'-伟业计量', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
                wx.updateTimelineShareData({
                  title: to+'-伟业计量', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
              });
            });
          });
        }
      }
    )
    watch(
      () => {
        return topic.value;
      },
      () => {
        nextTick(() => {
          imgList.length = 0
          const imgDom = document.querySelectorAll(".topic-content img");
          imgDom.forEach((img) => {
            imgList.push({ src: img.getAttribute("src") as string });
          });
        });
      }
    );
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        // console.log(to, from)
        if (to[0].topicId && !from[1].valid && to[0].topicId != from[0].topicId) {
          getRenderData(to[0].topicId as string);
        }
      }
    );
    const user = inject("userInfo") as object;
    const userInfo = inject("userInfo") as any;
    function onStar() {
      const data = qs.stringify({
        id: (topic.value as any).id,
        type: 1,
      });
      axios
        .post("/M/Server/DoLikeEntity", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (likeUser.value as object[]).unshift(user);
            likeUserCount.value += 1;
            likeCount.value = 1;
            // totalCount.value += 1;
            topic.value.isAlreadyZan = 1;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
           console.log(err);
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }
    const comment = ref("");
    function subComment(delText: any) {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        Tid: (topic.value as any).id,
        TContent: comment.value,
      });
      axios
        .post("/M/Server/DoPostPosts", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (listComment.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              content: comment.value,
              addTime: new Date().toISOString(),
              likeCount: 0,
              user: {
                ...user,

                userImg: (user as any).userImg,
              },
            });
            delText();
            totalCount.value = Number(totalCount.value)
              ? Number(totalCount.value) + 1
              : 1;
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
           console.log(err);
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }
    function onStarComment(id: string) {
      const data = qs.stringify({
        id,
        type: 1,
      });
      axios
        .post("/M/Server/DoLikeComment", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            for (let i = 0; i < (listComment.value as []).length; i++) {
              const element = listComment.value[i];
              if (element.id == id) {
                element.isAlreadyZan = 1;
                element.likeCount += 1;
              }
            }
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
           console.log(err);
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }

    function onCollect(isCollect: boolean) {
      const data = qs.stringify({
        id: (topic.value as any).id,
        type: 6,
      });
      axios
        .post("/M/Server/Collection", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            isFav.value = true;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
           console.log(err);
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }
    const router = useRouter();
    function editTopic(id: string) {
      router.push({
        name: "PostTopic",
        query: {
          type: "edit",
          id,
        },
      });
    }
    function apply(id: string) {
      Modal({
        title: "提示",
        content: "您确定要申请精华帖",
        onConfirm: () => {

          axios
            .post(
              "/M/Server/ApplyEssentiayTopic",
              qs.stringify({
                topicid: id,
              }),
              {
                params: {
                  validate: true,
                },
              }
            )
            .then((res) => {
              if (res.data.success) {
                Toast({
                  type: "success",
                  title: res.data.msg,
                });
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
               console.log(err);
              Toast({
                type: "error",
                title: "未知错误，请联系管理员",
              });
            });
        },
      });
    }
      const destoryKeepAlive = inject(
      "destoryKeepAlive"
    ) as () => Promise<unknown>;
    function del(id: string) {
      Modal({
        title: "提示",
        content: "您确定要删除本帖?",
        onConfirm: () => {
          axios
            .post(
              "/M/Discover/delThisTopic",
              qs.stringify({
                topicid: id,
              }),
              {
                params: {
                  validate: true,
                },
              }
            )
            .then((res) => {
              if (res.data.success) {
                Toast({
                  type: "success",
                  title: res.data.msg,
                  onClose: () => {
                    // router.back();
                    // location.reload();
                  },
                });

               destoryKeepAlive().then(() => {
                   router.back();
                  });
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
               console.log(err);
              Toast({
                type: "error",
                title: "未知错误，请联系管理员",
              });
            });
        },
      });
    }

    return {
      topic,
      myuserid,
      my,
      recommendList,
      likeUser,
      likeCount,
      likeUserCount,
      totalCount,
      listComment,
      dateFormat,
      loadOption,
      imgList,
      previewing,
      currentIndex,
      loading,
      closePreview,
      isFav,
      comment,
      subComment,
      onCollect,
      onStarComment,
      onStar,
      editTopic,
      del,
      apply,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 70px;
  text-align: left;
}
h2{
  font-size: 16px;
  font-weight: normal;
}
.topic-header {
  padding: 15px 10px;
}

.topic-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  padding: 0 0 10px 0;

  width: 100%;
  color: #666;
  font-family: "Microsoft YaHei";
  span {
    color: #df0024;
  }
}

.topic-author-info {
  position: relative;
  padding-left: 49px;
  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
}

.author-detail {
  color: #999999;
  font-size: 14px;
  margin-right: 5px;
  display: inline-block;
  a {
    color: #999999;
    font-size: 14px;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #20c2d6;
    font-size: 15px;
    a {
      color: #444444;
      font-size: 15px;
    }
  }
  .topic-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
  }
}

.topic-content {
  padding: 0 10px;

  font-size: 15px;
  font-family: "微软雅黑" !important;
  line-height: 25px !important;
  margin: 20px 10px;
  color: #666;
  :deep(p) {
    margin: 10px 0;
  }
  :deep(img) {
    width: 100%;
  }
  :deep(.topic-content-txt) {
    img, video {
      width: 100% !important;
    }
    h2{
      font-size: 16px;
    }
    a{
      color: #3498db;
      text-decoration: underline;
    }
  }
  :deep(video) {
    width: 100%;
  }
}
.topic-img-list {
  float: left;
  width: 32.6%;
  height: 29.1vw;
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
}

.topic-img-list:nth-child(3n-1) {
  margin: 0 3px;
}

.my-gallery figure {
  float: left;
  position: relative;
  transition: border-color 0.2s ease-out;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.topic-picture {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  .img-dv {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-right: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  video {
    margin: 0 auto;

    display: block;
  }
}
</style>
